import { gql } from '@apollo/client'

import {
  FRAGMENT_TASK_FIELDS,
  FRAGMENT_USER_FIELDS,
  FRAGMENT_USER_DATES_ROLES_FIELDS,
  FRAGMENT_USER_OWNER_FIELDS,
  FRAGMENT_USER_AUTOCOMPLETE_FIELDS,
  FRAGMENT_PAGINATION_META_FIELDS,
  FRAGMENT_EXTERNAL_USER_AUTOCOMPLETE_WITH_ASSIGNMENTS_FIELDS,
  FRAGMENT_TEMPLATE_TASK_FIELDS,
  FRAGMENT_TEMPLATE_CATEGORY_FOLDER,
} from 'graphql/fragments'

const SNIPPETS_QUERY_STRING = `
  snippets {
    id
    name
    value
    fallbackValue
    snippetType
  }
`
export const HAS_ERROR = gql`
  query HasError {
    hasError @client
  }
`
export const HAS_SUCCESS = gql`
  query HasSuccess {
    hasSuccess @client
  }
`

export const IS_LOGGED_IN = gql`
  query IsLoggedIn {
    isLoggedIn @client
  }
`

export const GET_LOCAL_CACHED_DATA = gql`
  query LocalCachedData {
    hasError
    hasSuccess
    isLoggedIn
  }
`

export const GET_CLIENT_CONFIG = gql`
  query ClientConfig {
    clientConfig {
      showBillingPortal
      showLocale
      showAuthorization
      showClientPages
      disableEmailTaskType
      ssoOrgId
      showCalendarEventTab
      workosOrganizationId
      disablePermissions
      enableSessionTracking
      hrisIntegrationSystem
      showDocusignTab
      enableSlack
      enableSlackTasks
      showNotificationOptOut
      showApiKeyTab
      enableMultipleHrisConnections
      showUpnAndEmployeeIdInputs
      enableFilterByTaxonomies
      enableMergeAts
      showDelegable
      showUserTemplateRoleAssignment
      showConfidentialNewHire
      hideExportEmployees
      enableMultipleTaxonomyTermsOnEmployee
      showAnalyticsOnboardingBuddy
      enableSendsafely
      showZoomIntegration
      disableContentLibrary
      customFields {
        key
        name
      }
      customFields {
        key
        name
      }
      enableSelectHrisIntegrationSystem
      enableDocebo
      disableSingleTaskSurvey
      enableEditButton
      editorName
      showNewReports
      showCalendarResource
      multipleCalendarAccount
      alwaysShowBothDayTypes
      useExternalUsersForRoleAssignments
      excludeSlackSendMessageToUser
      excludeSlackSendMessageToChannel
      excludeSlackChannelInvite
      excludeSlackGroupInvite
      excludeSlackKickUser
    }
  }
`

export const GET_SLACK_INSTALL_PATH = gql`
  query SlackInstallPath {
    slackInstallPath {
      installPath
      alreadyInstalled
      __typename
    }
  }
`

export const GET_WELCOME_EMAIL_SETTINGS = gql`
  query ClientWelcomeEmail($emailType: String!) {
    clientWelcomeEmail(emailType: $emailType) {
      clientId
      text
      subject
      daysBeforeStartDate
      timezone
      sendAt
      ccTo {
        label
        value
        type
      }
      from {
        label
        value
        type
      }
    }
  }
`

export const GET_CLIENT_BILLING_PORTAL = gql`
  query clientBillingPortal {
    clientBillingPortal {
      sessionUrl
    }
  }
`

export const GET_CLIENT_API_KEY = gql`
  query clientApiKey {
    clientApiKey {
      apiKey
    }
  }
`

export const GET_EMPLOYEE_ONBOARDING_ANALYTICS = gql`
  query employeeOnboardingAnalytics {
    employeeOnboardingAnalytics {
      feedbackUrl
      activityUrl
    }
  }
`

export const GET_CURRENT_USER = gql`
  query User {
    user {
      id
      isAdmin: isAdminByClient
      fullName
      email
      role {
        id
        name
        roleType
        permissions {
          id
          title
          entityType
          entityId
        }
      }
      clientId
      mainClient {
        id
        name
        isEmailFromVerified
        timezone
      }
      preferences {
        showInactiveTemplates
        usersTableState
        templateTasksTableState
      }
      clientAncestry {
        id
        name
      }
    }
  }
`

export const GET_USER_INFO_BY_EMAIL = gql`
  query UserInfo($email: String!) {
    userInfo(email: $email) {
      id
      email
      firstName
      lastName
      isTemp
      clientId
      upn
      externalId
    }
  }
`

export const GET_LOCATION_HIERARCHY_TREE = gql`
  query LocationHierarchyTree($topLevelCustomerId: ID!, $userId: ID) {
    locationHierarchyTree(
      topLevelCustomerId: $topLevelCustomerId
      userId: $userId
    )
  }
`

export const GET_USER_LOCATION_HIERARCHIES = gql`
  query UserLocationHierarchies($userId: ID!) {
    userLocationHierarchies(userId: $userId) {
      ownedLocationHierarchies {
        id
        templateCategoryId
      }
      assignedLocationHierarchies {
        id
        templateCategoryId
      }
    }
  }
`

export const GET_LOCATION_HIERARCHIES = gql`
  query LocationHierarchies($limitToAssignmentsIfAvail: Boolean!) {
    locationHierarchies(limitToAssignmentsIfAvail: $limitToAssignmentsIfAvail) {
      id
      title
    }
  }
`

export const GET_TASK_ANALYTICS_HOME_QUERY = gql`
  query TasksAnalytics(
    $date: ISO8601Date!
    $range: String
    $isCustomer: Boolean
    $query: String
    $sort: SortInput
    $pagination: PaginationInput
  ) {
    tasksAnalytics(
      date: $date
      range: $range
      isCustomer: $isCustomer
      query: $query
      sort: $sort
      pagination: $pagination
    ) {
      columns
      tree
      paginationMeta {
        ...PaginationMetaFields
      }
    }
  }
  ${FRAGMENT_PAGINATION_META_FIELDS}
`

export const GET_ANALYTICS_HOME_QUERY = gql`
  query SessionsLocations($startDate: ISO8601Date!) {
    sessionsLocations(startDate: $startDate) {
      assignedLocations {
        id
        fullHierarchyTitle
        state
        rootNode {
          owner {
            ...UserOwnerFields
          }
        }
      }
      managerialLocations {
        id
        fullHierarchyTitle
        state
        rootNode {
          owner {
            ...UserOwnerFields
          }
        }
      }
    }
    ${SNIPPETS_QUERY_STRING}
  }
  ${FRAGMENT_USER_OWNER_FIELDS}
`

export const GET_TASKS = gql`
  query Tasks($locationHierarchyId: ID!) {
    tasks(locationHierarchyId: $locationHierarchyId) {
      ...TaskFields
    }
  }
  ${FRAGMENT_TASK_FIELDS}
`

export const GET_LOCATION_HIERARCHY = gql`
  query LocationHierarchy($locationId: ID!) {
    locationHierarchy(locationId: $locationId) {
      id
      templateCategoryId
      fullHierarchyTitle
      rootNode {
        owner {
          ...UserOwnerFields
        }
      }
    }
  }
  ${FRAGMENT_USER_OWNER_FIELDS}
`

export const GET_CUSTOMERS = gql`
  query Customers {
    customers {
      id
      title
      state
    }
  }
`

export const GET_USER = gql`
  query User($id: ID!) {
    user(userId: $id) {
      ...UserFields
    }
  }
  ${FRAGMENT_USER_FIELDS}
`

export const GET_USER_DATES_AND_ROLES = gql`
  query User($id: ID!) {
    user(userId: $id) {
      ...UserDatesAndRolesFields
    }
  }
  ${FRAGMENT_USER_DATES_ROLES_FIELDS}
`

export const GET_USERS = gql`
  query Users(
    $query: String
    $pagination: PaginationInput
    $filterModel: UserFilterInput
    $sortModel: [UserSortInput!]
  ) {
    users(
      query: $query
      pagination: $pagination
      filterModel: $filterModel
      sortModel: $sortModel
    ) {
      data {
        ...UserFields
      }
      paginationMeta {
        ...PaginationMetaFields
      }
    }
  }
  ${FRAGMENT_USER_FIELDS}
  ${FRAGMENT_PAGINATION_META_FIELDS}
`

export const GET_USERS_IDS = gql`
  query Users(
    $query: String
    $pagination: PaginationInput
    $filterModel: UserFilterInput
    $sortModel: [UserSortInput!]
  ) {
    users(
      query: $query
      pagination: $pagination
      filterModel: $filterModel
      sortModel: $sortModel
    ) {
      data {
        id
      }
    }
  }
`

export const GET_ALL_USERS = gql`
  query Users($query: String) {
    users(query: $query, pagination: { paginate: false }) {
      data {
        ...UserFields
      }
    }
  }
  ${FRAGMENT_USER_FIELDS}
`

export const GET_USERS_AUTOCOMPLETE = gql`
  query Users($query: String, $pagination: PaginationInput) {
    users(query: $query, pagination: $pagination) {
      data {
        ...UserAutocompleteFields
      }
      paginationMeta {
        ...PaginationMetaFields
      }
    }
  }
  ${FRAGMENT_USER_AUTOCOMPLETE_FIELDS}
  ${FRAGMENT_PAGINATION_META_FIELDS}
`

export const GET_PAGES = gql`
  query Pages {
    contentPages(showAll: true) {
      id
      title
      taxonomyTermId
    }
  }
`

export const GET_PAGE = gql`
  query Pages($pageId: ID!) {
    contentPage(pageId: $pageId) {
      id
      slug
      title
      iconLibraryId
      iconUrl
      sections {
        id
        sectionType
        hidden
        content
        contentRaw
        pictureUrl
        icon1Url
        icon2Url
        icon3Url
        icon4Url
        icon5Url
        icon6Url
        icon7Url
        icon8Url
        icon9Url
        icon10Url
      }
    }
  }
`

export const GET_TEMPLATE_TASKS_FOR_DUPLICATION = gql`
  query TemplateTasks($categoryId: ID!) {
    templateTasks(categoryId: $categoryId) {
      id
      internalName
      title
      inactive
      position
      templateCategory {
        id
        category
        internalCategory
        inactive
        position
        __typename
      }
      __typename
    }
  }
`

export const GET_TEMPLATE_TASKS = gql`
  query TemplateTasks($categoryId: ID!) {
    templateTasks(categoryId: $categoryId) {
      ...TemplateTaskFields
    }
  }
  ${FRAGMENT_TEMPLATE_TASK_FIELDS}
`

export const GET_ALL_TEMPLATE_TASKS = gql`
  query AllTemplateTasks {
    allTemplateTasks {
      id
      title
      taskType
      internalName
      questions {
        id
        title
        required
        description
        explanation
        order
        quizType
        updatedAt
        options {
          id
          order
          title
          description
          score
          correct
        }
      }
      templateCategory {
        id
        category
        internalCategory
        parent {
          id
          category
          internalCategory
        }
      }
    }
  }
`

export const GET_DEPENDS_ON_TEMPLATE_TASKS = gql`
  query TemplateTasks($categoryId: ID!) {
    templateTasks(categoryId: $categoryId) {
      id
      title
      taskType
      internalName
      templateCategory {
        id
        category
        internalCategory
      }
    }
  }
`

export const GET_TEMPLATE_TASK = gql`
  query TemplateTask($id: ID!) {
    templateTask(id: $id) {
      ...TemplateTaskFields
    }
  }
  ${FRAGMENT_TEMPLATE_TASK_FIELDS}
`

export const GET_TEMPLATE_CATEGORIES = gql`
  query TemplateCategories(
    $templateCategoryFolderId: ID
    $templateCategoriesWithoutFolder: Boolean
  ) {
    templateCategories(
      templateCategoryFolderId: $templateCategoryFolderId
      templateCategoriesWithoutFolder: $templateCategoriesWithoutFolder
    ) {
      id
      position
      category
      categoryRaw
      internalCategory
      notifyOnCantComplete {
        label
        value
        type
      }
      createdAt
      inactive
      expireAfterDays
      templateTags {
        id
        name
        color
      }
      owner {
        ...UserFields
      }
      templateCategoryFolder {
        id
        title
      }
      disableNewAssignmentEmails
    }
  }
  ${FRAGMENT_USER_FIELDS}
`

export const GET_TEMPLATE_CATEGORIES_WITH_CHILDREN = gql`
  query TemplateCategories(
    $templateCategoryFolderId: ID
    $templateCategoriesWithoutFolder: Boolean
  ) {
    templateCategories(
      templateCategoryFolderId: $templateCategoryFolderId
      templateCategoriesWithoutFolder: $templateCategoriesWithoutFolder
    ) {
      id
      position
      category
      categoryRaw
      internalCategory
      notifyOnCantComplete {
        label
        value
        type
      }
      createdAt
      inactive
      expireAfterDays
      templateTags {
        id
        name
        color
      }
      owner {
        ...UserFields
      }
      templateCategoryFolder {
        id
        title
      }
      disableNewAssignmentEmails
      children {
        id
        position
        category
        categoryRaw
        internalCategory
        parent {
          id
        }
      }
    }
  }
  ${FRAGMENT_USER_FIELDS}
`

export const GET_TEMPLATE_CATEGORY = gql`
  query TemplateCategories($templateCategoryId: ID!) {
    templateCategories(templateCategoryId: $templateCategoryId) {
      id
      category
      internalCategory
      categoryRaw
      inactive
    }
  }
`

export const GET_LOCATION_HIERARCHY_NOTES = gql`
  query LocationHierarchyNotes($locationHierarchyId: ID!) {
    locationHierarchyNotes(locationHierarchyId: $locationHierarchyId)
  }
`

export const GET_LOCATION_HIERARCHY_TASKS = gql`
  query LocationHierarchyTasks(
    $locationHierarchyId: ID!
    $includeChildLocations: Boolean
    $fromRootNode: Boolean
  ) {
    locationHierarchyTasks(
      locationHierarchyId: $locationHierarchyId
      includeChildLocations: $includeChildLocations
      fromRootNode: $fromRootNode
    ) {
      id
      title
      internalName
      assignments {
        userId
        templateRoleId
      }
    }
  }
`

export const GET_MULTIPLE_OPTIONS = gql`
  query MultipleOptions {
    multipleOptions {
      id
      clientId
      options {
        id
        option
      }
    }
  }
`

export const GET_TEMPLATE_ROLES = gql`
  query TemplateRoles($showHidden: Boolean, $onlyAssignable: Boolean) {
    templateRoles(showHidden: $showHidden, onlyAssignable: $onlyAssignable) {
      id
      name
      isAssignable
    }
  }
`

export const GET_DATE_TYPES = gql`
  query DateTypes {
    dateTypes {
      id
      name
      baseDateTypeId
      clientId
      targetDateCohort {
        id
        dateCohortDates {
          toDate
        }
      }
    }
  }
`
export const GET_TEMPLATE_CATEGORY_FOLDERS = gql`
  query TemplateCategoryFolders($id: ID) {
    templateCategoryFolders(id: $id) {
      ...TemplateCategoryFolderFields
      templateCategories {
        id
        internalCategory
        inactive
        templateTags {
          id
        }
      }
    }
  }
  ${FRAGMENT_TEMPLATE_CATEGORY_FOLDER}
`

export const TEMPLATE_CATEGORY_HIERARCHY = gql`
  query TemplateCategoryHierarchy($id: ID!) {
    templateCategoryHierarchy(id: $id)
  }
`

export const TEMPLATE_CATEGORY_HIERARCHIES = gql`
  query TemplateCategoryHierarchies($ids: [ID!]!) {
    templateCategoryHierarchies(ids: $ids)
  }
`

export const GET_TAXONOMIES = gql`
  query Taxonomies {
    taxonomies {
      id
      name
      key
      baseTaxonomyId
      editable
      isLocation
    }
  }
`

export const GET_TAXONOMY_TERM_HIERARCHY = gql`
  query TaxonomyTermHierarchy($taxonomyId: ID!) {
    taxonomyTermHierarchy(taxonomyId: $taxonomyId)
  }
`
export const GET_SNIPPETS = gql`
  query Snippets {
    ${SNIPPETS_QUERY_STRING}
  }
`

export const GET_WORKOS_ADMIN_PORTAL_URL = gql`
  query WorkosAdminPortalRedirectUrl {
    workosAdminPortalRedirectUrl {
      redirectUrl
    }
  }
`

export const GET_METABASE_REDIRECT_URL = gql`
  query MetabaseRedirectUrl {
    metabaseRedirectUrl {
      token
    }
  }
`

export const GET_AUTHORIZATION_TOKENS = gql`
  query AuthorizationTokens {
    authorizationTokens {
      id
      systemOfRecord
      tokenIssuedAt
      tokenIssuer
      latestUserImportRun {
        status
        startedAt
        endedAt
      }
      latestTaxonomyImportRun {
        status
        startedAt
        endedAt
      }
    }
  }
`

export const GET_LATEST_IMPORT_RUNS = gql`
  query LatestImportRuns {
    latestImportRuns {
      clientId
      latestUserImportRun {
        status
        startedAt
        endedAt
      }
      latestTaxonomyImportRun {
        status
        startedAt
        endedAt
      }
    }
  }
`

export const GET_AUTO_IMPORT_RULES = gql`
  query AutoImportRules {
    autoImportRules {
      id
      inactive
      offboarding
      taxonomyTerms {
        id
        title
        taxonomyId
      }
      excludedTaxonomyTerms {
        id
        title
        taxonomyId
      }
      templateCategory {
        id
        category
        internalCategory
      }
      autoImportRuleDateSetting {
        dateType {
          id
          name
        }
        fromDateDays
        fromDateRelation
      }
      rulesTemplateRolesUsers {
        userId
        user {
          id
          email
          firstName
          lastName
        }
        templateRoleId
      }
    }
  }
`

export const GET_CLIENT_SETTING = gql`
  query ClientSetting {
    clientSetting {
      taxonomyImportEnabled
      atsTaxonomyImportEnabled
      userImportEnabled
      atsUserImportEnabled
      docusign
      userImport {
        importJoining
        importLeaving
        importAllUsers
        joiningDays
        leavingDays
      }
      atsUserImport {
        importJoining
        importLeaving
        importAllUsers
        joiningDays
        leavingDays
      }
      calendarIntegration {
        type
        email
        timezone
        connected
        calendarId
        workdayStartTime
        meetingLengthMinutes
      }
      zoomAccounts {
        name
        email
        favorite
      }
      calendarAccounts {
        email
      }
      doceboAccounts {
        name
        email
        baseUrl
      }
      gotoAccounts {
        name
        email
      }
      availableLanguages {
        label
        value
      }
    }
  }
`

export const GET_RESOURCES_HIERARCHY = gql`
  query ResourcesHierarchy($isEditing: Boolean) {
    resourcesHierarchy(isEditing: $isEditing) {
      id
      title
      titleRaw
      position
      parentId
      inactive
      children {
        id
      }
      resources {
        id
        title
        titleRaw
        url
        position
        inactive
        offboarding
        preboarding
        favorite
        iconUrl
        iconLibraryId
        taxonomyTerms {
          id
          title
          taxonomyId
        }
        excludedTaxonomyTerms {
          id
          title
          taxonomyId
        }
        templateRoles {
          id
          name
        }
      }
    }
  }
`

export const GET_PERMISSIONS = gql`
  query Permissions {
    permissions {
      id
      title
      children {
        id
        title
        folderTitle
        children {
          id
          title
        }
      }
    }
  }
`

export const GET_ROLE_PERMISSIONS = gql`
  query RolePermissions($roleId: ID!) {
    rolePermissions(roleId: $roleId) {
      visibility
      permissions {
        id
        title
      }
    }
  }
`
export const GET_ROLES = gql`
  query Roles {
    roles {
      id
      name
      roleType
    }
  }
`

export const GET_ROLE_USERS = gql`
  query Role($roleId: ID!) {
    role(roleId: $roleId) {
      name
      users {
        fullName
        email
      }
    }
  }
`

export const GET_MERGE_TOKEN = gql`
  query MergeToken {
    mergeToken
  }
`

export const GET_USERS_WITHOUT_TEMPLATE_TASK = gql`
  query UsersWithoutTemplateTask($templateTaskId: ID!) {
    usersWithoutTemplateTask(templateTaskId: $templateTaskId) {
      id
      email
      firstName
      lastName
      fullName
    }
  }
`

export const GET_CALENDAR_EVENTS = gql`
  query CalendarEvents($calendarId: String!, $calendarOwner: String!) {
    calendarEvents(calendarId: $calendarId, calendarOwner: $calendarOwner) {
      id
      name
      calendarId
      calendarOwnerEmail
      isRecurring
      nextOccurrences
    }
  }
`

export const GET_CALENDARS = gql`
  query Calendars($email: String) {
    calendars(email: $email) {
      id
      name
    }
  }
`

export const GET_NEXT_OCCURRENCE_DATES = gql`
  query CalendarEventOccurrences(
    $calendarEvents: [TemplateTaskCalendarEventInput!]!
  ) {
    calendarEventOccurrences(calendarEvents: $calendarEvents)
  }
`

export const GET_DOCUSIGN_TEMPLATES = gql`
  query DocusignTemplates {
    docusignTemplates {
      id
      name
    }
  }
`

export const GET_NOTIFICATION_TYPES = gql`
  query NotificationTypes {
    notificationTypes {
      title
      type
      typeId: id
      denied
    }
  }
`

export const GET_TAGS = gql`
  query TemplateTags {
    templateTags {
      color
      id
      name
    }
  }
`
export const GET_TEMPLATE_ROLE_USERS = gql`
  query TemplateRoleUsers {
    templateRoleUsers {
      id
      priority
      taxonomyTerms {
        id
        title
        taxonomyId
      }
      excludedTaxonomyTerms {
        id
        title
        taxonomyId
      }
      rulesTemplateRolesUsers {
        userId
        user {
          id
          email
          firstName
          lastName
        }
        templateRoleId
      }
    }
  }
`

export const GET_EXTERNAL_USERS = gql`
  query ExternalUsers($query: String, $pagination: PaginationInput) {
    externalUsers(query: $query, pagination: $pagination) {
      data {
        ...UserAutocompleteFields
      }
      paginationMeta {
        ...PaginationMetaFields
      }
    }
  }
  ${FRAGMENT_EXTERNAL_USER_AUTOCOMPLETE_WITH_ASSIGNMENTS_FIELDS}
  ${FRAGMENT_PAGINATION_META_FIELDS}
`

export const GET_ICON_LIBRARY = gql`
  query IconLibrary {
    iconLibrary {
      id
      name
      url
      editable
    }
  }
`

export const GET_USER_DEFAULT_TEMPLATE_ROLE_USERS = gql`
  query User($id: ID!) {
    user(userId: $id) {
      defaultTemplateRolesUsers {
        templateRoleId
        userId
      }
    }
  }
`

export const ANALYTICS_SURVEY = gql`
  query AnalyticsSurvey {
    analyticsSurvey
  }
`

export const ANALYTICS_NEW_SURVEY = gql`
  query AnalyticsNewSurvey($userIds: [ID!]) {
    analyticsNewSurvey(userIds: $userIds)
  }
`

export const ANALYTICS_SCORECARD = gql`
  query AnalyticsScorecard {
    analyticsScorecard
  }
`

export const GET_DATE_COHORTS = gql`
  query DateCohorts {
    dateCohorts {
      id
      name
      dateTypeId
      dateType {
        id
        name
      }
      targetDateType {
        id
        name
      }
      dateCohortDates {
        id
        toDate
        name
      }
    }
  }
`

export const TEMPLATE_TASK_HTML_DESCRIPTION = gql`
  query TemplateTaskHTMLDescription($templateTaskId: ID!) {
    templateTaskHtmlDescription(templateTaskId: $templateTaskId) {
      id
      title
      description
    }
  }
`

export const SLACK_CHANNELS = gql`
  query SlackChannels {
    slackChannels {
      id
      name
    }
  }
`

export const GET_CONTENT_ITEMS = gql`
  query ContentItems {
    contentItems {
      id
      title
      description
      descriptionRaw
      position
      contentItemsSectionId
    }
  }
`

export const GET_CONTENT_ITEMS_SECTIONS = gql`
  query ContentItemsSections {
    contentItemsSections {
      id
      title
      position
      contentItems {
        id
        title
        description
        descriptionRaw
        position
        contentItemsSectionId
      }
    }
  }
`

export const GET_TEMPLATE_TASKS_FROM_CONTENT_ITEM = gql`
  query TemplateTasksFromContentItem($contentItemId: ID!) {
    templateTasksFromContentItem(contentItemId: $contentItemId) {
      id
      title
      internalName
      templateCategory {
        id
        ancestry
        category
        internalCategory
      }
    }
  }
`

export const GET_BULK_ASSIGN_TEMPLATES = gql`
  query BulkAssignTemplates($userId: ID) {
    bulkAssignTemplates(userId: $userId) {
      imports {
        id
        status
        createdAt
        finishedAt
        reimport
        user {
          id
          fullName
          email
        }
        assignments {
          id
          user {
            id
            fullName
            email
          }
          templateCategory {
            id
            category
          }
          error
          createdAt
        }
      }
      reimports {
        id
        status
        createdAt
        finishedAt
        reimport
        length
        user {
          id
          fullName
          email
        }
        errors {
          id
          user {
            id
            fullName
            email
          }
          templateCategory {
            id
            category
          }
          error
          createdAt
        }
        workflows {
          id
          category
        }
      }
      deletes {
        id
        status
        createdAt
        finishedAt
        user {
          id
          fullName
          email
        }
        assignments {
          id
          user {
            id
            fullName
            email
          }
          templateCategory {
            id
            category
          }
          error
          createdAt
        }
      }
    }
  }
`

export const GET_BULK_ASSIGN_TEMPLATE = gql`
  query BulkAssignTemplate($id: ID!) {
    bulkAssignTemplate(id: $id) {
      id
      status
      createdAt
      finishedAt
      reimport
      user {
        id
        fullName
        email
      }
      assignments {
        id
        user {
          id
          fullName
          email
        }
        templateCategory {
          id
          category
        }
        error
        createdAt
      }
    }
  }
`

export const GET_USERS_WITH_TEMPLATE_TASK = gql`
  query UsersWithTemplateTask($id: ID!) {
    usersWithTemplateTask(id: $id) {
      id
      email
      firstName
      lastName
      fullName
      startDate
    }
  }
`

export const GET_DOCEBO_COURSES = gql`
  query DoceboCourses {
    doceboCourses {
      id
      name
    }
  }
`
export const GET_CLIENT_CONFIGURATIONS = gql`
  query Configuration {
    configuration {
      clientLogo
      backgroundUrl
      welcomeIconUrl
      primaryColor
      fontColor
      iconColor
      cantCompleteAnnouncement
      categoryColors
      emailHeaderIconId
      emailFooterIconId
      emailHeaderUrl
      emailFooterUrl
      emailBackgroundColor
      emailBorderColor
    }
  }
`

export const GET_USERS_WITH_TEMPLATE_CATEGORY = gql`
  query UsersWithTemplateCategory($templateCategoryId: ID!) {
    usersWithTemplateCategory(templateCategoryId: $templateCategoryId) {
      id
      email
      firstName
      lastName
      fullName
    }
  }
`

export const GET_CLIENT_SECRET = gql`
  query ClientSecret($issuer: String!) {
    clientSecret(issuer: $issuer) {
      id
      issuer
      identifier
      secretHint
      data
    }
  }
`

export const GET_SCORM_COURSE = gql`
  query ScormCourse($id: ID!) {
    scormCourse(id: $id) {
      id
      title
      uploadState
    }
  }
`

export const GET_TEMPLATE_TASKS_CONNECTED_TO_DATE_TYPE = gql`
  query TemplateTasksConnectedToDateType($dateTypeId: ID!) {
    templateTasksConnectedToDateType(dateTypeId: $dateTypeId) {
      id
    }
  }
`
export const GET_TEMPLATE_TASK_OVERRIDE_RULES = gql`
  query TemplateTaskOverrideRules($templateTaskId: ID!) {
    templateTaskOverrideRules(templateTaskId: $templateTaskId) {
      id
      userGroup {
        userGroupConditions {
          id
          entityId
          value
        }
      }
      overrideValues {
        id
        value
        taskPropertyName
      }
    }
  }
`
export const GET_REPORTS = gql`
  query Reports {
    reports {
      id
      name
      message
      config
      latestReportJob {
        state
      }
      reportDateSetting {
        dateType {
          id
          name
          baseDateType {
            id
            name
          }
        }
        days
        relation
        useBusinessDays
      }
      viewConfig
    }
  }
`

export const GET_LATEST_REPORT_JOB = gql`
  query LatestReportJob($id: ID!) {
    latestReportJob(id: $id) {
      id
      state
      data
    }
  }
`

export const GET_TEMPLATE_TASK_CHANGES = gql`
  query TemplateTaskChanges($templateTaskId: ID!) {
    templateTaskChanges(templateTaskId: $templateTaskId) {
      id
      user {
        id
        fullName
      }
      property
      change
      createdAt
    }
  }
`

export const GET_CALENDAR_RESOURCES = gql`
  query CalendarResources {
    calendarResources {
      id
      name
      email
    }
  }
`

export const GET_CALENDAR_CATEGORIES = gql`
  query CalendarCategories {
    calendarCategories {
      id
      name
      color
    }
  }
`
export const GET_PREVIEW_TASKS = gql`
  query previewTasks(
    $dateFilters: [PreviewTaskDateFilterInput!]!
    $templateCategoryIds: [String!]
    $taskTypes: [String!]
    $taxonomyTermIds: [String!]
  ) {
    previewTasks(
      dateFilters: $dateFilters
      templateCategoryIds: $templateCategoryIds
      taskTypes: $taskTypes
      taxonomyTermIds: $taxonomyTermIds
    ) {
      id
      title
      internalName
      dueDate
      taskType
      templateCategoryId
      start
      end
    }
  }
`
